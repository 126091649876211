<template>
  <div>
    <!-- =====================================isCompany is false===================================== -->
    <customer-details v-if="!getClientBasicInfo.isCompany">
      <template v-slot:btnPrope>
        <v-btn
          class="t-btn--prm mr-5 ml-2"
          :disabled="!checkPerUser"
          @click="actionUpdateBasic()"
        >
          {{ $t('task_29786.keep') }}
        </v-btn>
      </template>

      <template v-slot:default>
        <div class="mx-5 my-5">
          <v-form ref="form">
            <v-card class="px-10 py-10">
              <h2 class="text--title mb-5">顧客情報(個人)</h2>
              <h5>顧客番号</h5>
              <h6>{{ $route.params.id }}</h6>
              <div>
                <v-row>
                  <!-- Basic personal information -->
                  <v-col :cols="6">
                    <h4 class="text--title mt-5">個人基本情報</h4>
                    <div class="mt-3">
                      <div class="w-75">
                        <v-row>
                          <v-col :cols="6">
                            <label for="">顧客名（姓）</label>
                            <v-text-field
                              dense
                              v-model="data.lastName"
                              :disabled="!checkPerUser"
                            ></v-text-field>
                          </v-col>
                          <v-col :cols="6">
                            <label for="">顧客名（名）</label>
                            <v-text-field
                              dense
                              v-model="data.firstName"
                              :disabled="!checkPerUser"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col :cols="6">
                            <label for="">フリガナ（姓）</label>
                            <v-text-field
                              dense
                              v-model="data.lastKana"
                              :disabled="!checkPerUser"
                            ></v-text-field>
                          </v-col>
                          <v-col :cols="6">
                            <label for="">フリガナ（名）</label>
                            <v-text-field
                              dense
                              v-model="data.firstKana"
                              :disabled="!checkPerUser"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col :cols="3">
                            <label for="">生年月日</label>
                            <v-text-field
                              dense
                              v-model="data.birthday"
                              :disabled="!checkPerUser"
                            ></v-text-field>
                          </v-col>
                          <v-col :cols="3">
                            <label for="">年齢</label>
                            <h6>
                              <!-- {{ getDay() }} -->
                              {{ getAgeFromDate(data.birthday) }}
                              <span>歳</span>
                            </h6>
                          </v-col>
                          <v-col :cols="3">
                            <label for="">問い合わせ時年齢</label>
                            <v-text-field
                              dense
                              type="number"
                              :value="data.firstContactAge"
                              @input="data.firstContactAge = $event ? parseInt($event) : null"
                              :disabled="!checkPerUser"
                            ></v-text-field>
                          </v-col>
                          <v-col :cols="3">
                            <v-select
                              label="性別"
                              v-model="data.sex"
                              :items="listGender"
                              item-text="name"
                              item-value="value"
                              :disabled="!checkPerUser"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col :cols="6">
                            <p>勤務先名</p>
                            <v-text-field
                              dense
                              v-model="data.employerName"
                              :disabled="!checkPerUser"
                            ></v-text-field>
                          </v-col>
                          <v-col :cols="6">
                            <p>勤務先名ふりがな</p>
                            <v-text-field
                              dense
                              v-model="data.employerKana"
                              :disabled="!checkPerUser"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col :cols="6">
                            <v-select
                              label="職業"
                              :items="listName"
                              item-text="name"
                              item-value="id"
                              v-model="data.employmentTypeId"
                              :disabled="!checkPerUser"
                            ></v-select>
                          </v-col>
                          <v-col :cols="6">
                            <v-select
                              :items="listClientChild"
                              label="子供"
                              item-text="name"
                              item-value="id"
                              v-model="data.childTypeId"
                              :disabled="!checkPerUser"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col :cols="6">
                            <v-select
                              :items="listLevelList"
                              label="年収"
                              item-text="name"
                              item-value="id"
                              v-model="data.incomeLevelId"
                              :disabled="!checkPerUser"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col :cols="12">
                            <h5 class="mb-2">他のスタッフへの要共有事項</h5>
                            <v-textarea
                              solo
                              name="input-7-4"
                              v-model="data.remarksForStaff"
                              :disabled="!checkPerUser"
                              :rules="[$rules.checkTextAreaLength()]"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-col>
                  <!-- End Basic personal information -->

                  <!-- Contact shipping address -->
                  <v-col :cols="6">
                    <h4 class="text--title mt-5">連絡先発送先</h4>
                    <div class="mt-3">
                      <v-row class="mx-4">
                        <v-col :cols="6">
                          <v-select
                            class="mt-3"
                            dense
                            label="発送先"
                            :items="listMail"
                            item-value="value"
                            item-text="name"
                            v-model="data.mailPreference"
                            :disabled="!checkPerUser"
                          ></v-select>
                        </v-col>
                        <v-col :cols="6">
                          <v-select
                            class="mt-3"
                            dense
                            label="電話連絡先"
                            :items="listMail"
                            item-text="name"
                            item-value="value"
                            v-model="data.telPreference"
                            :disabled="!checkPerUser"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <template v-if="data.contacts && data.contacts.length > 0">
                        <v-expansion-panels class="mb-10">
                          <v-expansion-panel
                            v-for="(item, i) in data.contacts"
                            :key="i"
                          >
                            <v-expansion-panel-header>
                              <div class="d-flex justify-stard">
                                <span v-if="item.type === 'HomeOrAddress'">
                                  自宅
                                </span>
                                <span v-if="item.type === 'WorkplaceOrNotary'">
                                  勤務先
                                </span>
                                <span v-if="item.type === 'Contact'">
                                  連絡先
                                </span>
                                <span v-if="item.type === 'Other'"> その他 </span>
                              </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    dense
                                    :label="labelsecfalse"
                                    v-model="item.labelName"
                                    single-line
                                    :disabled="!checkPerUser"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col :cols="6">
                                  <v-text-field
                                    dense
                                    label="郵便番号"
                                    v-model="item.postalCode"
                                    :rules="[$rules.postalCodeNoHyphen]"
                                    :disabled="!checkPerUser"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col :cols="6">
                                  <!-- v-model="item.prefecture" -->
                                  <v-select
                                    dense
                                    label="都道府県"
                                    :items="listDistrict"
                                    item-text="name"
                                    item-value="name"
                                    v-model="item.prefecture"
                                    :disabled="!checkPerUser"
                                  ></v-select>
                                </v-col>
                                <v-col :cols="6">
                                  <v-text-field
                                    dense
                                    label="市区郡"
                                    v-model="item.locality"
                                    :disabled="!checkPerUser"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    dense
                                    label="町名・番地"
                                    v-model="item.address1"
                                    :disabled="!checkPerUser"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    dense
                                    label="ビル名、マンション名等"
                                    v-model="item.address2"
                                    :disabled="!checkPerUser"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    dense
                                    label="電話番号"
                                    v-model="item.tel"
                                    :disabled="!checkPerUser"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    dense
                                    label="携帯電話番号"
                                    v-model="item.mobile"
                                    :disabled="!checkPerUser"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    dense
                                    label="FAX番号"
                                    v-model="item.fax"
                                    :disabled="!checkPerUser"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    dense
                                    label="メールアドレス"
                                    v-model="item.email"
                                    :disabled="!checkPerUser"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </template>
                    </div>
                  </v-col>
                  <!-- End Contact shipping address -->

                  <!-- --sec-new-- -->
                  <div class="w-90 float-right ml-auto pr-3 mb-8">
                    <v-divider></v-divider>
                    <h2 class="text--title mx-5 mb-6 mt-10">カード</h2>
                    <v-row class="mx-4">
                      <v-col :cols="6">
                        <h5>TOPカードポイントID</h5>
                        <card-number
                          class="d-flex w-35 align-center"
                          style="margin-left: -20px"
                          v-model="data.topCardNumber"
                        />
                      </v-col>
                      <v-col :cols="6" class="d-flex align-center">
                        <div>
                          <h5>GMOカード登録状況</h5>
                          <h6>
                            {{ data.registeredCC === null ? '未登録' : '登録済' }}
                          </h6>
                        </div>
                        <v-btn
                          class="t-btn--prm ml-4"
                          :disabled="!checkPerUser"
                          @click="isOpenSendRequestDialog = true"
                        >
                          カード登録依頼メールを送信
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <div class="mx-5">
                      <h2 class="text--title mb-6 mt-10">その他情報</h2>
                      <v-row>
                        <v-col cols="6 w-80">
                          <h5>作成日</h5>
                          <h6 style="color: #000" class="mb-3">
                            {{ data.createdAt | formatDateISO }}
                          </h6>
                          <v-row class="mb-2">
                            <v-col :cols="6">
                              <h5>紹介者顧客番号</h5>
                              <v-text-field
                                single-line
                                v-model="clientId"
                                :rules="rules.checkClient"
                                ref="clientIdField"
                                :disabled="!checkPerUser"
                              ></v-text-field>
                            </v-col>
                            <v-col :cols="6">
                              <h5 class="mt-2">紹介者顧客名</h5>
                              <v-text-field
                                single-line
                                v-text="
                                  data.referrerClient
                                    ? data.referrerClient.name
                                    : ''
                                "
                                :disabled="!checkPerUser"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col :cols="6">
                              <h5 class="mt-1">紹介者グループ社員</h5>
                              <v-text-field
                                single-line
                                :disabled="!checkPerUser"
                                v-model="data.referrerGroupEmployeeName"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col :cols="3">
                              <h5 class="mt-1">ファーストアクセス日</h5>
                              <template v-if="listDate && listDate.length > 0">
                                <div v-for="item in listDate" :key="item.id">
                                  <v-menu
                                    v-model="menuDate[listDate.indexOf(item)]"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    :disabled="!checkPerUser"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <div
                                        class="d-flex justify-start border-hiden"
                                      >
                                        <v-text-field
                                          single-line
                                          :disabled="!checkPerUser"
                                          v-model="data.firstAccessDate"
                                          v-on="on"
                                        ></v-text-field>
                                      </div>
                                    </template>
                                    <v-date-picker
                                      v-model="data.firstAccessDate"
                                      :first-day-of-week="0"
                                      :locale="$i18n.locale"
                                      scrollable
                                      @input="
                                        menuDate1[listDate.indexOf(item)] = false
                                      "
                                      class="v-date-picker-custom"
                                    ></v-date-picker>
                                  </v-menu>
                                </div>
                              </template>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col :cols="3">
                              <h5 class="mt-1">入会日</h5>
                              <template v-if="listDate && listDate.length > 0">
                                <div v-for="item in listDate" :key="item.id">
                                  <v-menu
                                    v-model="menuDate2[listDate.indexOf(item)]"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    :disabled="!checkPerUser"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <div
                                        class="d-flex justify-start border-hiden"
                                      >
                                        <v-text-field
                                          single-line
                                          :disabled="!checkPerUser"
                                          v-model="data.membershipStarted"
                                          v-on="on"
                                        ></v-text-field>
                                      </div>
                                    </template>
                                    <v-date-picker
                                      v-model="data.membershipStarted"
                                      :first-day-of-week="0"
                                      :locale="$i18n.locale"
                                      scrollable
                                      @input="
                                        menuDate1[listDate.indexOf(item)] = false
                                      "
                                      class="v-date-picker-custom"
                                    ></v-date-picker>
                                  </v-menu>
                                </div>
                              </template>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col :cols="3">
                              <h5 class="mt-1">退会日</h5>
                              <template v-if="listDate && listDate.length > 0">
                                <div v-for="item in listDate" :key="item.id">
                                  <v-menu
                                    v-model="menuDate1[listDate.indexOf(item)]"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    :disabled="!checkPerUser"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <div
                                        class="d-flex justify-start border-hiden"
                                      >
                                        <v-text-field
                                          single-line
                                          :disabled="!checkPerUser"
                                          v-model="
                                            data.membershipCancellationDate
                                          "
                                          v-on="on"
                                        ></v-text-field>
                                      </div>
                                    </template>
                                    <v-date-picker
                                      v-model="data.membershipCancellationDate"
                                      :first-day-of-week="0"
                                      :locale="$i18n.locale"
                                      scrollable
                                      :min="data.membershipStarted"
                                      @input="
                                        menuDate1[listDate.indexOf(item)] = false
                                      "
                                      class="v-date-picker-custom"
                                    ></v-date-picker>
                                  </v-menu>
                                </div>
                              </template>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col :cols="6">
                              <h5>退会理由</h5>
                              <v-text-field
                                single-line
                                v-model="data.membershipCancellationReason"
                                :disabled="!checkPerUser"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="6">
                          <div class="w-75 ml-auto">
                            <h5>旧TBOSの顧客番号</h5>
                            <h6>{{ data.tbosClientId }}</h6>
                            <h5 class="mt-16 mb-5">備考</h5>
                            <v-textarea
                              solo
                              name="input-7-4"
                              v-model="data.remarks"
                              :disabled="!checkPerUser"
                              :rules="[$rules.checkTextAreaLength()]"
                            ></v-textarea>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-row>
              </div>
            </v-card>
          </v-form>
        </div>
      </template>
    </customer-details>

    <!-- ===================================顧客情報(法人) isCompany is true============================ -->
    <customer-details v-else>
      <template v-slot:btnPrope>
        <v-btn
          class="t-btn--prm mr-5 ml-2"
          :disabled="!checkPerUser"
          @click="actionUpdateGroup()"
        >
          {{ $t('task_29786.keep') }}
        </v-btn>
      </template>
      <div class="mx-5 my-5">
        <v-form ref="form">
          <v-card class="px-10 py-10">
            <h2 class="text--title mb-5">顧客情報(法人)</h2>
            <h5>顧客番号</h5>
            <h6>{{ $route.params.id }}</h6>
            <div>
              <v-row>
                <!-- Basic personal information -->
                <v-col :cols="6">
                  <h4 class="text--title mt-5">法人基本情報</h4>
                  <div class="mt-3">
                    <div class="w-75">
                      <v-row>
                        <v-col>
                          <label for="">社名</label>
                          <v-text-field
                            dense
                            v-model="data.companyName"
                            :disabled="!checkPerUser"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <label for="">フリガナ</label>
                          <v-text-field
                            dense
                            v-model="data.companyNameKana"
                            :disabled="!checkPerUser"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col :cols="6">
                          <label for="">法人窓口担当者</label>
                          <v-text-field
                            dense
                            v-model="data.companyPicName"
                            :disabled="!checkPerUser"
                          ></v-text-field>
                        </v-col>
                        <v-col :cols="6">
                          <label for="">法人窓口担当者フリガナ</label>
                          <v-text-field
                            dense
                            v-model="data.companyPicKana"
                            :disabled="!checkPerUser"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col :cols="6">
                          <label>法人代表者名</label>
                          <v-text-field
                            dense
                            v-model="data.companyRepresentativeName"
                            :disabled="!checkPerUser"
                          ></v-text-field>
                        </v-col>
                        <v-col :cols="6">
                          <label>法人代表者名フリガナ</label>
                          <v-text-field
                            dense
                            v-model="data.companyRepresentativeKana"
                            :disabled="!checkPerUser"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col :cols="6">
                          <label>法人窓口担当者部署</label>
                          <v-text-field
                            dense
                            v-model="data.companyPicDepartment"
                            :disabled="!checkPerUser"
                          ></v-text-field>
                        </v-col>
                        <v-col :cols="6">
                          <label>法人窓口担当者役職名</label>
                          <v-text-field
                            dense
                            v-model="data.companyPicTitle"
                            :disabled="!checkPerUser"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col :cols="6">
                          <label>従業員数</label>
                          <v-text-field
                            dense
                            v-model="data.companySize"
                            :disabled="!checkPerUser"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col :cols="12">
                          <h5 class="mb-2">他のスタッフへの要共有事項</h5>
                          <v-textarea
                            solo
                            name="input-7-4"
                            v-model="data.remarksForStaff"
                            :disabled="!checkPerUser"
                            :rules="[$rules.checkTextAreaLength()]"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-col>
                <!-- End Basic personal information -->

                <!-- Contact shipping address -->
                <v-col :cols="6">
                  <h4 class="text--title mt-5">連絡先発送先</h4>
                  <div class="mt-3">
                    <v-row class="mx-4">
                      <v-col :cols="6">
                        <v-select
                          class="mt-3"
                          dense
                          label="発送先"
                          :items="telPreferenceList"
                          item-value="value"
                          item-text="name"
                          :disabled="!checkPerUser"
                          v-model="data.mailPreference"
                        ></v-select>
                      </v-col>
                      <v-col :cols="6">
                        <v-select
                          class="mt-3"
                          dense
                          label="電話連絡先"
                          item-value="value"
                          item-text="name"
                          :items="telPreferenceList"
                          :disabled="!checkPerUser"
                          v-model="data.telPreference"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <template v-if="data.contacts && data.contacts.length > 0">
                      <v-expansion-panels class="mb-10 panels-cus">
                        <v-expansion-panel
                          v-for="(item, i) in data.contacts"
                          :key="i"
                        >
                          <v-expansion-panel-header>
                            <div class="d-flex justify-stard">
                              <span v-if="item.type === 'HomeOrAddress'">
                                会社情報
                              </span>
                              <!-- <span v-if="item.type === 'WorkplaceOrNotary'">
                                記名人
                              </span> -->
                              <span v-if="item.type === 'Contact'"> 連絡先 </span>
                              <span v-if="item.type === 'Other'"> その他 </span>
                            </div>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  dense
                                  :label="labelsec1"
                                  v-model="item.labelName"
                                  single-line
                                  :disabled="!checkPerUser"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col :cols="6">
                                <v-text-field
                                  dense
                                  label="郵便番号"
                                  v-model="item.postalCode"
                                  :rules="[$rules.postalCodeNoHyphen]"
                                  :disabled="!checkPerUser"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col :cols="6">
                                <v-select
                                  dense
                                  label="都道府県"
                                  :items="listDistrict"
                                  item-text="name"
                                  item-value="name"
                                  v-model="item.prefecture"
                                  :disabled="!checkPerUser"
                                ></v-select>
                              </v-col>
                              <v-col :cols="6">
                                <v-text-field
                                  dense
                                  label="市区郡"
                                  v-model="item.locality"
                                  :disabled="!checkPerUser"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-text-field
                                  dense
                                  label="町名・番地"
                                  v-model="item.address1"
                                  :disabled="!checkPerUser"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-text-field
                                  dense
                                  label="ビル名、マンション名等"
                                  v-model="item.address2"
                                  :disabled="!checkPerUser"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-text-field
                                  dense
                                  label="電話番号"
                                  v-model="item.tel"
                                  :disabled="!checkPerUser"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-text-field
                                  dense
                                  label="携帯電話番号"
                                  v-model="item.mobile"
                                  :disabled="!checkPerUser"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-text-field
                                  dense
                                  label="FAX番号"
                                  v-model="item.fax"
                                  :disabled="!checkPerUser"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-text-field
                                  dense
                                  label="メールアドレス"
                                  v-model="item.email"
                                  :disabled="!checkPerUser"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </template>
                  </div>
                </v-col>
                <!-- End Contact shipping address -->

                <!-- --sec-new-- -->
                <div class="w-90 float-right ml-auto pr-3 mb-8">
                  <v-divider></v-divider>
                  <h2 class="text--title mx-5 mb-6 mt-10">カード</h2>
                  <v-row class="mx-4">
                    <v-col :cols="6">
                      <h5>TOPカードポイントID</h5>
                      <card-number
                        class="d-flex w-35 align-center"
                        style="margin-left: -20px"
                        v-model="data.topCardNumber"
                      />
                    </v-col>
                    <v-col :cols="6" class="d-flex align-center">
                      <div>
                        <h5>GMOカード登録状況</h5>
                        <h6>
                          {{ data.registeredCC === null ? '未登録' : '登録済' }}
                        </h6>
                      </div>
                      <v-btn
                        class="t-btn--prm ml-4"
                        :disabled="!checkPerUser"
                        @click="isOpenSendRequestDialog = true"
                      >
                        カード登録依頼メールを送信
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <div class="mx-5">
                    <h2 class="text--title mb-6 mt-10">その他情報</h2>
                    <v-row>
                      <v-col cols="6 w-80">
                        <h5>作成日</h5>
                        <h6 style="color: #000" class="mb-3">
                          {{ data.createdAt | formatDateISO }}
                        </h6>
                        <v-row class="mb-2">
                          <v-col :cols="6">
                            <h5>紹介者顧客番号</h5>
                            <v-text-field
                              single-line
                              v-model="clientId"
                              ref="clientIdField"
                              :rules="rules.checkClient"
                              :disabled="!checkPerUser"
                            ></v-text-field>
                          </v-col>
                          <v-col :cols="6">
                            <h5 class="mt-2">紹介者顧客名</h5>
                            <v-text-field
                              single-line
                              v-text="
                                data.referrerClient
                                  ? data.referrerClient.name
                                  : ''
                              "
                              :disabled="!checkPerUser"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col :cols="6">
                            <h5 class="mt-1">紹介者グループ社員</h5>
                            <v-text-field
                              single-line
                              v-model="data.referrerGroupEmployeeName"
                              :disabled="!checkPerUser"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col :cols="3">
                            <h5 class="mt-1">ファーストアクセス日</h5>
                            <template v-if="listDate && listDate.length > 0">
                              <div v-for="item in listDate" :key="item.id">
                                <v-menu
                                  v-model="menuDate[listDate.indexOf(item)]"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                  :disabled="!checkPerUser"
                                >
                                  <template v-slot:activator="{ on }">
                                    <div
                                      class="d-flex justify-start border-hiden"
                                    >
                                      <v-text-field
                                        single-line
                                        :disabled="!checkPerUser"
                                        v-model="data.firstAccessDate"
                                        v-on="on"
                                      ></v-text-field>
                                    </div>
                                  </template>
                                  <v-date-picker
                                    v-model="data.firstAccessDate"
                                    :first-day-of-week="0"
                                    :locale="$i18n.locale"
                                    scrollable
                                    @input="
                                      menuDate[listDate.indexOf(item)] = false
                                    "
                                    class="v-date-picker-custom"
                                  ></v-date-picker>
                                </v-menu>
                              </div>
                            </template>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col :cols="3">
                            <h5 class="mt-1">入会日</h5>
                            <template v-if="listDate2 && listDate2.length > 0">
                              <div v-for="item in listDate2" :key="item.id">
                                <v-menu
                                  v-model="menuDate2[listDate2.indexOf(item)]"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                  :disabled="!checkPerUser"
                                >
                                  <template v-slot:activator="{ on }">
                                    <div
                                      class="d-flex justify-start border-hiden"
                                    >
                                      <v-text-field
                                        single-line
                                        :disabled="!checkPerUser"
                                        v-model="data.membershipStarted"
                                        v-on="on"
                                      ></v-text-field>
                                    </div>
                                  </template>
                                  <v-date-picker
                                    v-model="data.membershipStarted"
                                    :first-day-of-week="0"
                                    :locale="$i18n.locale"
                                    scrollable
                                    @input="
                                      menuDate2[listDate2.indexOf(item)] = false
                                    "
                                    class="v-date-picker-custom"
                                  ></v-date-picker>
                                </v-menu>
                              </div>
                            </template>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col :cols="3">
                            <h5 class="mt-1">退会日</h5>
                            <template v-if="listDate1 && listDate1.length > 0">
                              <div v-for="item in listDate1" :key="item.id">
                                <v-menu
                                  v-model="menuDate1[listDate1.indexOf(item)]"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                  :disabled="!checkPerUser"
                                >
                                  <template v-slot:activator="{ on }">
                                    <div
                                      class="d-flex justify-start border-hiden"
                                    >
                                      <v-text-field
                                        single-line
                                        :disabled="!checkPerUser"
                                        v-model="data.membershipCancellationDate"
                                        v-on="on"
                                      ></v-text-field>
                                    </div>
                                  </template>
                                  <v-date-picker
                                    v-model="data.membershipCancellationDate"
                                    :first-day-of-week="0"
                                    :locale="$i18n.locale"
                                    scrollable
                                    @input="
                                      menuDate1[listDate1.indexOf(item)] = false
                                    "
                                    class="v-date-picker-custom"
                                  ></v-date-picker>
                                </v-menu>
                              </div>
                            </template>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col :cols="6">
                            <h5>退会理由</h5>
                            <v-text-field
                              single-line
                              v-model="data.membershipCancellationReason"
                              :disabled="!checkPerUser"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <div class="w-75 ml-auto">
                          <h5>旧TBOSの顧客番号</h5>
                          <h6>{{ data.tbosClientId }}</h6>
                          <h5 class="mt-16 mb-5">備考</h5>
                          <v-textarea
                            solo
                            name="input-7-4"
                            v-model="data.remarks"
                            :rules="[$rules.checkTextAreaLength()]"
                            :disabled="!checkPerUser"
                          ></v-textarea>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-row>
            </div>
          </v-card>
        </v-form>
      </div>
    </customer-details>

    <v-dialog
      v-model="isOpenSendRequestDialog"
      max-width="480"
      content-class="pa-4 bg--white rounded-lg"
    >
      <h2 class="font-weight-bold text--heading text-center">
        カード登録依頼メールを送信します
      </h2>
      <div class="text-right mt-6">
        <v-btn class="t-btn--prm mr-4" @click="isOpenSendRequestDialog = false"
          >閉じる</v-btn
        >
        <v-btn
          class="t-btn--red-dark"
          @click="
            sendCCRegisterRequest($route.params.id),
              (isOpenSendRequestDialog = false)
          "
          >送信</v-btn
        >
      </div>
    </v-dialog>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CustomerDetails from './CustomerDetails.vue';
// import { calculateDays } from '@/utils/dateUtil.js';
import { getAgeFromDate } from '@/utils/dateUtil';
import { ENUM_EMPLOYMENT_TYPE_LIST } from '@/api/graphql/enum/enumEmploymentTypeList';
import { ENUM_CLIENT_CHILD_TYPE_LIST } from '@/api/graphql/enum/enumClientChildTypeList';
import { ENUM_INCOME_LEVEL_LIST } from '@/api/graphql/enum/enumIncomeLevelList';
import { ENUM_PREFECTURE_LIST } from '@/api/graphql/enum/enumPrefectureList';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions';
import { SEND_CC_REGISTER_REQUEST } from '@/api/graphql/customer/CustomerDetail';
import CardNumber from '@/components/Input/CardNumber.vue';
// import { set } from 'vue/types/umd';
// import router from '@/router';
// import checkNumber from '@/utils/validate.js';

export default {
  components: {
    CustomerDetails,
    CardNumber,
  },
  name: 'BasicInformation',
  data() {
    return {
      isOpenSendRequestDialog: false,
      arrCarNumber: [' ', ' ', ' ', ' '],
      data: {},
      isAssetClient: true,
      rules: {
        checkClient: [
          (v) =>
            (!!v && this.isAssetClient) || !v || this.$t('IDが存在しません'),
        ],
      },
      listMail: [
        {
          name: '自宅',
          value: 'HomeOrAddress',
        },
        {
          name: '勤務先',
          value: 'WorkplaceOrNotary',
        },
        {
          name: '連絡先',
          value: 'Contact',
        },
        {
          name: 'その他',
          value: 'Other',
        },
      ],
      telPreferenceList: [
        {
          name: '会社情報',
          value: 'HomeOrAddress',
        },
        {
          name: '連絡先',
          value: 'Contact',
        },
        {
          name: 'その他',
          value: 'Other',
        },
      ],
      listGender: [
        {
          name: '女性',
          value: 'Female',
        },
        {
          name: '男性',
          value: 'Male',
        },
        {
          name: 'その他',
          value: 'Other',
        },
      ],
      changeDataBasic: null,
      dataUpdate: {},
      newFinishData: {},
      menuDate: [],
      menuDate1: [],
      menuDate2: [],
      listDate: [{ id: 1, date: new Date().toISOString().substr(0, 10) }],
      listDate1: [{ id: 1, date: new Date().toISOString().substr(0, 10) }],
      listDate2: [{ id: 1, date: new Date().toISOString().substr(0, 10) }],
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      cardNumber1: '',
      cardNumber2: '',
      cardNumber3: '',
      cardNumber4: '',
      nameValue: '',
      listName: [],
      listClientChild: [],
      nameClientChild: null,
      listLevelList: [],
      nameLevelList: '',
      // nameDistrict: '',
      listDistrict: [],
      // employmentTypeSelected: '',
      // createdAt: '',
      // sex: '',
      // getS: '',
      // type: '',
      // getSeclectContractType: '',
      // tuy: false,
      labelsecfalse: 'ラベル用宛名',
      labelsec1: 'ラベル用宛名(部署名　役職名　宛名の順でご記入下さい)'
    };
  },
  computed: {
    clientId: {
      get() {
        return this.data.referrerClientId;
      },
      set(val) {
        this.data.referrerClientId = val;
        if (val) {
          this.searchTimeOut(val);
        } else {
          this.data.referrerClient = null;
        }
      },
    },
    ...mapGetters([
      'getClientBasicInfo',
      'getClientId',
      'getLastName',
      'getEnumEmploymentTypeList',
      'getEnumClientChildTypeList',
      'getEnumIncomeLevelList',
      'getEnumPrefectureList',
    ]),
    email() {
      return !this.$store.getters.user ? false : this.$store.getters.user.email;
    },
  },
  created() {
    // this.$store.dispatch('GetUserInfo');
  },
  watch: {
    getClientBasicInfo() {
      this.data = this.getClientBasicInfo;
    },
  },
  mounted() {
    this.getData();
    this.getListLevelList();
    this.enumEmploymentTypeList().then(() => {
      this.getList();
    });
    this.enumClientChildTypeList();
    this.enumIncomeLevelList();
    this.getListCliendChild();
    this.getPrefectureList();
    this.enumPrefectureList();
  },
  methods: {
    getAgeFromDate,
    handlErrorView,
    getCopyErrorTextView,
    ...mapActions([
      'clientBasicInfo',
      'enumEmploymentTypeList',
      'enumClientChildTypeList',
      'enumIncomeLevelList',
      'enumPrefectureList',
      'UpdateBasicInfomation',
      'UpdateBasicInfoGroup',
    ]),
    ...mapMutations([
      'setCopyErrorText',
      'setClientBasicInfoReferrerClient',
      'setClientId',
      'setLastName',
      'setEnumEmploymentTypeList',
      'setEnumClientChildTypeList',
      'setEnumIncomeLevelList',
      'setEnumPrefectureList',
      'setAlertError',
      'setPermissionUser',
      'setRoleAdminUser',
      'setLoadingOverlayShow',
      'setLoadingOverlayHide',
      'setAlertSuccess',
    ]),
    checkNumber(v) {
      return Number.isInteger(parseInt(v));
    },
    checkLength(v){
      if(v.length === 4){
        return true
      }else{
        return false
      }
    },
    getDay() {
      if (this.getClientBasicInfo.birthday)
        return (
          new Date().getFullYear() -
          new Date(this.getClientBasicInfo.birthday).getFullYear()
        );
    }, 

    async sendCCRegisterRequest(id) {
      this.setLoadingOverlayShow();
      const variables = {
            clientId: parseInt(id),
          }
      await this.$apollo
        .mutate({
          mutation: gql`${SEND_CC_REGISTER_REQUEST}`,
          variables: variables,
        })
        .then((data) => {
          this.setLoadingOverlayHide();
          this.setAlertSuccess('リクエストを正常に送信する');
        })
        .catch(async (error) => {
          this.setLoadingOverlayHide();
          this.setCopyErrorText(this.getCopyErrorTextView(SEND_CC_REGISTER_REQUEST, variables, error.graphQLErrors))
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },

    async getList() {
      await this.$apollo
        .query({
          query: gql`${ENUM_EMPLOYMENT_TYPE_LIST}`,
        })
        .then((data) => {
          this.listName = data.data.enumEmploymentTypeList;
        })
        .catch(async (error) => {
          this.setCopyErrorText(this.getCopyErrorTextView(ENUM_EMPLOYMENT_TYPE_LIST, null, error.graphQLErrors))
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },

    async getListCliendChild() {
      await this.$apollo
        .query({
          query: gql`${ENUM_CLIENT_CHILD_TYPE_LIST}`,
        })
        .then((data) => {
          const listClientChild = [];
          data.data.enumClientChildTypeList.forEach((element) => {
            switch (element.id) {
              case 11:
                listClientChild.push({ id: 11, name: '0歳以上3歳未満' });
                break;
              case 21:
                listClientChild.push({ id: 21, name: '3歳以上6歳未満' });
                break;
              case 31:
                listClientChild.push({ id: 31, name: '6歳以上13歳未満' });
                break;
              case 41:
                listClientChild.push({ id: 41, name: '13歳以上' });
                break;

              default:
                break;
            }
          });
          this.listClientChild = listClientChild;
        })
        .catch(async (error) => {
          this.setCopyErrorText(this.getCopyErrorTextView(ENUM_CLIENT_CHILD_TYPE_LIST, null, error.graphQLErrors))
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },

    async getListLevelList() {
      await this.$apollo
        .query({
          query: gql`${ENUM_INCOME_LEVEL_LIST}`,
        })
        .then((data) => {
          this.listLevelList = data.data.enumIncomeLevelList;
        })
        .catch(async (error) => {
          this.setCopyErrorText(this.getCopyErrorTextView(ENUM_INCOME_LEVEL_LIST, null, error.graphQLErrors))
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },

    async getPrefectureList() {
      await this.$apollo
        .query({
          query: gql`${ENUM_PREFECTURE_LIST}`,
        })
        .then((data) => {
          this.listDistrict = data.data.enumPrefectureList;
        })
        .catch(async (error) => {
          this.setCopyErrorText(this.getCopyErrorTextView(ENUM_PREFECTURE_LIST, null, error.graphQLErrors))
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },

    getData() {
      this.clientBasicInfo().then(() => {
        this.nameValue = this.getClientBasicInfo.employmentTypeId;
        this.nameClientChild = this.getClientBasicInfo.childTypeId;
        this.nameLevelList = this.getClientBasicInfo.incomeLevelId;
      });
    },
    actionUpdateBasic() {
      // this.dataUpdate.topCardNumber = this.arrCarNumber
      //   .toString()
      //   .replace(/,/g, '-');
      // if(this.$refs.form.validate()){
        this.UpdateBasicInfomation(this.data);
      // }
    },

    actionUpdateGroup() {
      // this.newFinishData.topCardNumber = this.arrCarNumber
      //   .toString()
      //   .replace(/,/g, '-');
      // if(this.$refs.form.validate()){
        this.UpdateBasicInfoGroup(this.data);
      // }
    },
    searchTimeOut(e) {  
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.checkClient(e)
      }, 400);
    },
    async checkClient(e) {
      await this.$apollo
        .query({
          query: gql`
            query ($id: Int!) {
              clientBasicInfo(id: $id) {
                name
              }
            }
          `,
          variables: {
            id: parseInt(e),
          },
        })
        .then((data) => {
          this.isAssetClient = true;
          this.$refs.clientIdField.resetValidation();
          this.data.referrerClient = {
            name: data.data.clientBasicInfo.name
          };
        })
        .catch((err) => {
          console.log(err);
          this.isAssetClient = false;
          this.$refs.clientIdField.validate();
          this.data.referrerClient = null;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.component {
  &-header,
  &-main {
    font-size: var(--unnamed-font-size-16);
    color: var(--text_active_000000);
    font-weight: 500;
  }
}
.blod {
  font-weight: bold;
}
h2:not(.text--heading) {
  font-size: 20px;
  font-weight: bold;
}
h4 {
  font-size: 18px !important;
  font-weight: 500;
  padding-bottom: 34px;
}
.text--title {
  color: #0c6786 !important;
}
h5 {
  font-size: 10px;
  font-weight: 600;
  color: #000000 !important;
}
h6 {
  font-size: 14px;
  font-weight: 500;
  color: #444;
  margin-top: 5px;
}
p {
  font-size: 10px;
  color: #000;
  font-weight: 500;
}
.intro-info {
  div {
    display: flex;
    label {
      width: 121px;
      flex-shrink: 0;
      text-align: right;
      margin-right: 16px;
    }
  }
}
.tab-custom {
  &__tab {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__total {
    width: 40%;
    text-align: right;
    align-self: flex-end;
    color: var(--text_active_000000);
  }
}
.info-detail-title {
  color: var(--text_active_000000);
}

.col-3:nth-child(4) {
  .v-text-field--is-booted {
    margin-top: 6px !important;
  }
  .v-select__selection .v-select__selection--comma {
    font-size: 10px !important;
  }
}
::v-deep {
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .v-input__control {
    display: table-column-group !important;
  }
  .col {
    .v-input * {
      font-size: 14px !important;
      .v-label {
        font-size: 10px !important;
      }
    }
  }
  .col-3 {
    .v-text-field__slot {
      input {
        color: #000 !important;
        font-weight: 500;
        font-size: 14px !important;
      }
    }
  }
  .w-80 {
    .col-6:nth-child(2) {
      .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
        border-style: none !important;
        border: none !important;
      }
      .v-text-field__slot {
        input {
          color: #000;
          font-weight: 500;
        }
      }
    }
    .col-3 {
      .v-label {
        font-size: 14px !important;
        font-weight: 500;
      }
    }
  }
  .col-3:nth-child(2) {
    .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
      border-style: none !important;
      border: none !important;
    }

    .theme--light .v-select .v-select__selections {
      font-size: 14px !important;
    }
  }
  .col-3:nth-child(4) {
    .v-input * {
      font-size: 14px !important;
      color: #000 !important;
      .v-label {
        font-size: 10px !important;
      }
    }
  }
  .col-6 {
    .v-select__selections {
      color: #444;
      font-weight: 500;
    }
    &:nth-child(9) {
      color: #444 !important;
    }
    .align-center {
      .v-text-field__slot {
        input {
          color: #000 !important;
          font-weight: 500;
        }
      }
      .v-input__control {
        height: 38px !important;
      }
    }
  }
  label,
  .v-label {
    font-size: 10px;
    color: #000000 !important;
    font-weight: 600;
    &--active {
      color: #000000 !important;
    }
  }
  .v-text-field__slot {
    input {
      font-size: 14px !important;
      color: #444444;
      font-weight: 500;
    }
  }
  .v-list-item__content {
    .v-list-item__title {
      font-weight: 500;
      font-size: 14px;
      color: #000000;
    }
  }
  .col-6 {
    .v-textarea {
      .v-input__slot {
        border: 1px solid #707070 !important;
        border-radius: 7px;
        min-height: 220px;
      }
    }
  }
  .col-12 {
    .v-input__slot {
      // border: 1px solid #707070 !important;
      border-radius: 7px;
    }
    .v-label,
    .theme--light.v-input textarea {
      color: #444444 !important;
      font-size: 14px !important;
      font-weight: 500;
      min-height: 220px;
    }
  }
  .w-60 {
    width: 60%;
  }
  .w-35 {
    max-width: 50%;
  }
  .w-75 {
    width: 75%;
  }
  .w-80 {
    width: 80%;
  }
  .w-90 {
    width: 90%;
  }
  .v-expansion-panel-content {
    padding: 0px 20px 0 0;
  }
  .v-expansion-panels {
    .v-expansion-panel-header {
      font-size: 16px !important;
      .justify-stard {
        span {
          color: #232222;
        }
      }
    }
    .col {
      .v-text-field__slot {
        input {
          font-size: 14px !important;
          font-weight: 500;
        }
      }
    }
    .v-label {
      font-size: 16px !important;
      color: #444444 !important;
      font-weight: 500;
    }
  }
  .v-divider {
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .float-right {
    .v-text-field {
      padding-top: 0px !important;
    }
    h5 {
      color: #000;
      font-weight: 600;
    }
    .w-80 {
      .row {
        margin-top: 0 !important;
      }
      .col-3,
      .col-6 {
        .v-input__control {
          height: 28px !important;
        }
      }
    }
  }
  .panels-cus {
    .v-expansion-panel:nth-child(2) {
      display: none;
    }
  }
}

@media (max-width: 600px) {
  .v-card__title {
    flex-direction: column;
    align-items: flex-end;
  }
  .intro-info div {
    display: block;
    label {
      width: auto;
      text-align: left;
    }
  }
}
.userIcon {
  height: 100px;
  width: 100px;
  margin: auto;
}
.listCmt {
  width: 1000px;
  border-bottom: 1px solid #d8d8d8;
  margin: auto;
  padding: 10px 0;
}
.borderComment {
  border: 1px solid #d8d8d8;
}
.colorUsername {
  color: green;
}
.headerCmt {
  border-bottom: 1px solid #d8d8d8;
  padding: 15px 15px 0px 15px;
}
.footerCmt {
  border-top: 1px solid #d8d8d8;
  padding: 15px;
}
.borderPaginate {
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
}
</style>