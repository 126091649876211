<template>
  <div>
    <v-text-field
      maxlength="4"
      single-line
      class="pl-5 pr-1"
      v-model="num1"
      :rules="rules"
      :disabled="disabled"
    ></v-text-field
    >-
    <v-text-field
      maxlength="4"
      single-line
      class="pl-5 pr-1"
      v-model="num2"
      :rules="rules"
      :disabled="disabled"
    ></v-text-field
    >-
    <v-text-field
      maxlength="4"
      single-line
      class="pl-5 pr-1"
      v-model="num3"
      :rules="rules"
      :disabled="disabled"
    ></v-text-field
    >-
    <v-text-field
      maxlength="4"
      single-line
      class="pl-5 pr-1"
      v-model="num4"
      :rules="rules"
      :disabled="disabled"
    ></v-text-field>
  </div>
</template>

<script>
import {translateZenkakuNumbers} from '@/utils/japanese'
export default {
  props: {
    value: String,
    disabled: Boolean
  },
  computed: {
    numbersBySection () {
      const v = (this.value ?? '').split('-').map(v => v && v.trim())
      return v.concat([null, null, null, null]).slice(0, 4)
    },
    num1: {
      get() { return this.numbersBySection[0]; },
      set(v) { this.change(0, v) }
    },
    num2: {
      get() { return this.numbersBySection[1]; },
      set(v) { this.change(1, v) }
    },
    num3: {
      get() { return this.numbersBySection[2]; },
      set(v) { this.change(2, v) }
    },
    num4: {
      get() { return this.numbersBySection[3]; },
      set(v) { this.change(3, v) }
    },
    rules () {
      const rules = []
      rules.push(this.$rules.checkLenghInput(4))
      rules.push(this.$rules.checkNumber)
      return rules
    }
  },
  methods: {
    change(index, value) {
      const result = this.numbersBySection.slice().map(v => v && v.trim())
      result[index] = (value ?? '').trim();
      if (result.every(v => !v)) {
        // if nothing is input, send null
        this.$emit('input', '')
      } else {
        this.$emit('input', translateZenkakuNumbers(result.join('-')))
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
